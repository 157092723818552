import React from "react"
import Vimeo from "@u-wave/react-vimeo"
import { Seo } from "../components/seo"
import { graphql } from "gatsby"

const LMAO = ({ data }) => {
  const videoId = data.sanitySiteSettings.of_youtube
  // useEffect(() => {
  //   window.location.replace('https://youtu.be/r_i-owe88EA')
  // }, [])
  return (
    <>
      <Seo title="PRD" noindex />
      <div className="text-center h-100 center-child">
        <Vimeo video={videoId} autoplay />
      </div>
    </>
  )
}

export default LMAO

export const query = graphql`
  query {
    sanitySiteSettings {
      of_youtube
    }
  }
`
